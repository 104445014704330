import "./App.css";
import StagTable from "./StagTable";

function App() {
  return (
    <>
      <StagTable />
    </>
  );
}

export default App;
